// Libraries
import { Controller } from '@hotwired/stimulus';

/**
 * @class HeightMatchController
 * @extends Controller
 * @description Loops through all elements in a query and sets their height to
 * match the highest element on the same horizontal line.
 */
class HeightMatchController extends Controller {
    static targets = ['match'];
    static values = { height: Number };

    /**
     * @method connect
     * @description Update heights on target elements after an initial delay.
     *
     * @return {void}
     */
    connect() {
        setTimeout(() => {
            this.updateHeights();
        }, 1000);
    }

    /**
     * @method windowResize
     * @description Update heights on target elements after a window resize.
     *
     * @return {void}
     */
    windowResize() {
        this.updateHeights();
    }

    /**
     * @method updateHeights
     * @description Find tallest in set of elements and update other elements to match.
     *
     * @param {HTMLElement[]} elements - The HTML elements to target
     * @return {void}
     */
    updateHeights() {
        const sortedElements = {};
        const topPositions = {};

        // Iterate through each element and find the maximum height for each top
        this.matchTargets.forEach((element) => {
            const top = element.getBoundingClientRect().top;
            const height = element.clientHeight;

            // Skip if the element is not visible
            if (height === 0) {
                return;
            }

            if (sortedElements[top] === undefined) {
                sortedElements[top] = [element];
            } else {
                sortedElements[top] = [...sortedElements[top], element];
            }

            if (topPositions[top] === undefined || topPositions[top] < height) {
                topPositions[top] = height;
            }
        });

        for (const top in sortedElements) {
            // Set the maximum height for each top position
            sortedElements[top].forEach((element) => {
                this.setHeight(element, topPositions[top]);
            });
        }
    }

    /**
     * @method setHeight
     * @description Explicitly set height on an element.
     *
     * @param {HTMLElement} target - The HTML element to target
     * @param {string|function} val - The function to perform or the value to set
     * @return {void}
     */
    setHeight(target, val) {
        if (typeof val === 'function') val = val();
        if (typeof val === 'string') target.style[prop] = val;
        else target.style.minHeight = val + 'px';
    }
}

// Exports
export default HeightMatchController;
