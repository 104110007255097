// Libraries
import { Controller } from '@hotwired/stimulus';

/**
 * @class PageController
 * @extends Controller
 * @description Registers the page header height and updates the offset of any
 * components that need to be positioned below.
 */
class PageController extends Controller {
    static targets = ['header', 'offset'];
    static values = { offset: Number };

    /**
     * @method connect
     * @description Set the offset value and to the header target height
     *
     * @return {void}
     */
    connect() {
        this.offsetValue = this.headerTarget.offsetHeight;
        this.applyOffsets();
    }

    /**
     * @method applyOffsets
     * @description Apply the offset value to the target elements
     *
     * @return {void}
     */
    applyOffsets() {
        this.offsetTargets.forEach((target) => {
            target.style.top = `${this.offsetValue}px`;
        });
    }
}

// Exports
export default PageController;
