// Libraries
import { Controller } from '@hotwired/stimulus';

/**
 * @class ProxyFormController
 * @extends Controller
 * @description Allows one form field to influence another's value.
 */
class ProxyFormController extends Controller {
    static targets = ['form', 'principal', 'proxy'];

    /**
     * @method setPrincipalValue
     * @description Set the value of the principal field to the value proxy the source field.
     *
     * @return {void}
     */
    setPrincipalValue(event) {
        const principalField = this.getPrincipalField(event.params.name);
        principalField.value = event.params.value;

        if (event.params.submit == 1) {
            this.triggerSubmit();
        }
    }

    /**
     * @method getPrincipalField
     * @description Determine the principal field based on the proxy field data.
     *
     * @return {void}
     */
    getPrincipalField(name) {
        return this.principalTargets.find(
            (principalField) => principalField.name === name
        );
    }

    /**
     * @method triggerSubmit
     * @description Submit the form by the proxy field.
     *
     * @return {void}
     */
    triggerSubmit() {
        this.formTarget.requestSubmit();
    }
}

// Exports
export default ProxyFormController;
