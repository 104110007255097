// Libraries
import { Controller } from '@hotwired/stimulus';

/**
 * @class WidthMatchController
 * @extends Controller
 * @description Loops through all elements in a query and sets their width to
 * match the widest element in the group.
 */
class WidthMatchController extends Controller {
    static targets = ['match'];
    static values = { width: { type: Number, default: 0 } };

    /**
     * @method connect
     * @description Update heights on target elements after an initial delay.
     *
     * @return {void}
     */
    connect() {
        setTimeout(() => {
            this.updateWidths();
        }, 1000);
    }

    /**
     * @method windowResize
     * @description Update heights on target elements after a window resize.
     *
     * @return {void}
     */
    windowResize() {
        this.updateWidths();
    }

    /**
     * @method updateWidths
     * @description Find tallest in set of elements and update other elements to match.
     *
     * @param {HTMLElement[]} elements - The HTML elements to target
     * @return {void}
     */
    updateWidths() {
        // Reset the width value
        this.widthValue = 0;

        // Iterate through each element and find the maximum height for each top
        this.matchTargets.forEach((element) => {
            const width = element.clientWidth;

            // Skip if the element is not visible
            if (width === 0) {
                return;
            }

            if (this.widthValue < width) {
                this.widthValue = width;
            }
        });

        this.matchTargets.forEach((element) => {
            this.setWidth(element, this.widthValue);
        });
    }

    /**
     * @method setWidth
     * @description Explicitly set height on an element.
     *
     * @param {HTMLElement} target - The HTML element to target
     * @param {string|function} val - The function to perform or the value to set
     * @return {void}
     */
    setWidth(target, val) {
        if (typeof val === 'function') val = val();
        if (typeof val === 'string') target.style[prop] = val;
        else target.style.minWidth = val + 'px';
    }
}

// Exports
export default WidthMatchController;
